import Link from "next/link";
import styles from "./productCard.module.scss";
import { svgImages } from "@/lib/svgImages";

const ProductCard = ({ url, icon, title }) => {
  return (
    <>
      <Link href={url} className={`${styles["productCardLink"]}`}>
        <div className={`${styles["productCard"]}`}>
          <div
            className={`${styles["productIcon"]}`}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
          <h2 className={`${styles["productTitle"]}`}>{title}</h2>
        </div>
      </Link>
    </>
  );
};

export default ProductCard;
