
import styles from "./heroGlobal.module.scss";
import React, { useState } from "react";
import Image from "next/image";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useEffect, useRef } from "react";
import RoundedButton from "@/components/roundedButton/roundedButton";
import Spline from "@splinetool/react-spline";
import Script from "next/script";
const HeroGlobal = ({
  showIcon,
  icon,
  staticTitle,
  title,
  description,
  buttonTitle,
  buttonUrl,
  btnDisplay,
  light,
  isProducts,
  isSolutions,
  isSingleSolution,
  isHome,
  heroImage,
}) => {
  function isMobile() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  const triggerRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);
  const text = title;

  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    if (isMobile()) {
      gsap.to(triggerRef.current, {
        width: "1400px",
        borderRadius: "15px",

        scrollTrigger: {
          trigger: triggerRef.current,
          start: "80% center",
          end: "+=1000",
          markers: false,
          pin: false,
          scrub: true,
        },
      });
    } else {
      gsap.to(triggerRef.current, {
        width: "1400px",
        maxWidth: "100vw",
        borderRadius: "60px",

        scrollTrigger: {
          trigger: triggerRef.current,
          start: "center center",
          end: "+=2000",
          markers: false,
          pin: false,
          scrub: true,
        },
      });
    }

    return () => {};
  }, []);

  return (
    <>
      <div
        className={`${styles["hero-container"]} ${
          isHome ? styles["homePage"] : ""
        } ${isSolutions ? styles["solutions"] : ""} ${
          isProducts ? styles["products"] : ""
        } ${isSingleSolution ? styles["isSingleSolution"] : ""}`}
        ref={triggerRef}
      >
        <div className={`${styles["hero"]}`}>
          <div
            className={`${styles["hero-col"]} ${styles["hero-text-container"]}`}
          >
            {showIcon == true ? (
              <Image
                className={`${styles["icon"]}`}
                src={icon}
                width={200}
                height={200}
                alt=""
              />
            ) : (
              ""
            )}

            <div className={`${styles["text"]}`}>
              <div className={`${styles["titleContainer"]}`}>
                <h1 className={`${styles["titleContainer"]}`}>
                  <span className={`${styles["staticTitle"]}`}>
                    {staticTitle}
                  </span>
                  <span
                    className={`${styles["dynamicTitle"]}`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></span>
                </h1>
              </div>

              <div className={`${styles["description"]}`}>{description}</div>
            </div>
            <div style={{ display: btnDisplay === false ? "none" : "block" }}>
              <RoundedButton
                title={buttonTitle}
                url={buttonUrl}
                light={light}
              />
            </div>
          </div>

          {heroImage}
        </div>
      </div>
    </>
  );
};

export default HeroGlobal;
