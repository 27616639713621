import styles from "./productsTabsSection.module.scss";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@nextui-org/react";
import ProductsTabsContent from "@/components/productsTabsContent/productsTabsContent";
import ToggleSwitch from "../toggelSwicther/toggelSwicther";
import { useEffect, useState } from "react";
import CustomSpinner from "../spinner/spinner";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Image from "next/image";
import { Products } from "@/lib/products";
import ProductCard from "../productCard/productCard";
import { svgImages } from "@/lib/svgImages";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const ProductsTabsSection = () => {
  const { t } = useTranslation();
  const [isDynamic, setIsDynamic] = useState(false);
  const [allList, setAllList] = useState([]);
  const [staticList, setStaticList] = useState([]);
  const [loading, setLoading] = useState(true);
  var staticListArr = [];

  useEffect(() => {
    for (let index = 0; index < Products.productsTabsSection.length; index++) {
      const element = Products.productsTabsSection[index];
      if (!element.isDynamic) {
        staticListArr.push(element);
        setStaticList(staticListArr);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      var allListArr = [];

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/products?per_page=100",
        { cache: "force-cache" }
      ).then((res) => res.json());
      // const data = await fetch("/products.json", { cache: "force-cache" }).then(
      //   (res) => res.json()
      // );
      if (data != undefined) {
        // setAllList(data);
        for (let index = 0; index < data.length; index++) {
          const element = data[index];

          // if (element?.acf.isdynamic == false && isDynamic) {
          //   staticListArr.push(element);
          //   setStaticList(staticListArr);
          // } else

          if (element?.acf.isdynamic == true) {
            allListArr.push(element);
            setAllList(allListArr);
          }
        }
        ScrollTrigger.refresh();
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <CustomSpinner />
        </>
      ) : (
        <>
          <div
            className={`${styles["productsTabsSectionDesktop"]} d-flex-md  d-none-xs`}
          >
            <ToggleSwitch
              isChecked={isDynamic}
              setIsChecked={setIsDynamic}
              firstVal={`Static`}
              secondVal={`Dynamic`}
              secondIcon={<AutoAwesomeIcon />}
              pricingPage={false}
            />
            {!isDynamic ? (
              <Tabs
                aria-label="Our Products"
                items={allList.sort((a, b) => (a.id < b.id ? -1 : 1))}
                className={`${styles["productsTabs"]}`}
              >
                {(item) => (
                  <Tab
                    className={`${styles["productsTab"]}`}
                    key={item.id}
                    title={
                      <>
                        <div className={`${styles["tabButton"]}`}>
                          <Image
                            src={`/images/products/icons/${item.slug}.svg`}
                            width={25}
                            height={25}
                            alt=""
                          />
                          <span>{item.acf.hero_title}</span>
                        </div>
                      </>
                    }
                  >
                    <ProductsTabsContent
                      icon={`/images/products/icons/${item.slug}.svg`}
                      title={item.acf.hero_title}
                      description={item.content.rendered}
                      url={
                        item.acf.isdynamic ? `/en/products/${item.slug}` : ""
                      }
                      image={`/images/products/${item.slug}.webp`}
                    />
                  </Tab>
                )}
              </Tabs>
            ) : (
              <Tabs
                aria-label="Our Products"
                items={staticList}
                className={`${styles["productsTabs"]}`}
              >
                {(item) => (
                  <Tab
                    className={`${styles["productsTab"]} ${styles["staticButton"]}`}
                    key={t(item.title)}
                    title={
                      <div className={`${styles["tabButton"]}`}>
                        <Image src={item.icon} width={25} height={25} alt="" />
                        <span>{t(item.title)}</span>
                      </div>
                    }
                  >
                    <ProductsTabsContent
                      icon={item.icon}
                      title={t(item.title)}
                      description={t(item.description)}
                      url={`/en/static-products`}
                      image={item.image}
                      isStatic={true}
                    />
                  </Tab>
                )}
              </Tabs>
            )}
          </div>
          <div
            className={`${styles["productsCardsMobile"]} d-none-md d-flex-xs`}
          >
            {allList &&
              allList
                .sort((a, b) => (a.id < b.id ? -1 : 1))
                .map((item) => (
                  <ProductCard
                    url={`/en/products/${item.slug}`}
                    icon={svgImages[item.slug.replace(/-/g, "_")]}
                    title={item.acf.hero_title}
                  />
                ))}
          </div>
        </>
      )}
    </>
  );
};

export default ProductsTabsSection;
