import Link from "next/link";
import styles from "./productsTabsContent.module.scss";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Image from "next/image";
import ButtonBgAnimate from "../buttonBgAnimate/buttonBgAnimate";

const ProductsTabsContent = ({
  icon,
  title,
  description,
  url,
  image,
  isStatic = false,
}) => {
  return (
    <div
      className={`${styles["productsTabsContent"]} ${
        isStatic ? styles["staticProduct"] : ""
      } `}
    >
      <div className={`${styles["left"]}`}>
        <Image src={icon} width={40} height={40} alt="" />
        <h2>{title}</h2>
        <p
          className={`${styles["description"]}`}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <div className={`${styles["buttonsContainer"]}`}>
          {url && (
            <Link href={url} prefetch={false}>
              <div className={`${styles["button"]}`}>
                Learn More
                <CallMadeIcon />
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className={`${styles["right"]}`}>
        <Image
          className="d-flex-md d-none-xs"
          src={image}
          width={358}
          height={726}
          alt=""
        />
      </div>
    </div>
  );
};

export default ProductsTabsContent;
