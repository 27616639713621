import { useState } from "react";
import styles from "./toggelSwicther.module.scss";

export default function ToggleSwitch({
  isChecked,
  setIsChecked,
  firstVal,
  secondIcon,
  secondVal,
  pricingPage,
}) {
  return (
    <>
      <div className={`${styles["toggleSwitchContainer"]}`}>
        <div className={`${styles["toggleSwitch"]}`}>
          <input
            type="radio"
            id="secondVal"
            name="toggle"
            checked={!isChecked}
            onChange={() => setIsChecked(false)}
          />
          <label
            htmlFor="secondVal"
            className={`${isChecked ? "" : styles["active"]}`}
          >
            {secondIcon}
            {secondVal}
          </label>
          <input
            type="radio"
            id="firstVal"
            name="toggle"
            checked={isChecked}
            onChange={() => setIsChecked(true)}
          />
          <label htmlFor="firstVal">{firstVal}</label>
          <div className={`${styles["switch"]}`}></div>
        </div>
        {pricingPage && (
          <div className={`${styles["offerText"]}`}>
            <span>Save up to 25% with our annual plans</span>
          </div>
        )}
      </div>
    </>
  );
}
