import styles from "./spinner.module.scss";
import { PulseRingsThree } from "react-svg-spinners";
const CustomSpinner = () => {
  return (
    <div className={`${styles["customSPinner"]}`}>
      <PulseRingsThree height={60} width={60} />
    </div>
  );
};

export default CustomSpinner;
