import styles from "./titleWithArrow.module.scss";
import { svgImages } from "@/lib/svgImages";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useEffect, useRef, useState } from "react";

const TitleWithArrow = ({
  title,
  description,
  withArrow = true,
  // triggerRef,
  // setLettersRef,
  // lettersRef,
}) => {
  const [lettersRef, setLettersRef] = useArrayRef();
  const triggerRef = useRef(null);

  function useArrayRef() {
    const lettersRef = useRef([]);
    lettersRef.current = [];
    return [lettersRef, (ref) => ref && lettersRef.current.push(ref)];
  }

  gsap.registerPlugin(ScrollTrigger);
  const text = title;

  function isMobile() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  useEffect(() => {
    if (isMobile()) {
      const anim = gsap.to(lettersRef.current, {
        scrollTrigger: {
          trigger: triggerRef.current,
          // scrub: true,
          start: "top 90%",
          end: "bottom 10%",
          markers: false,
        },
        color: "#000000",
        // duration: 0,
        stagger: 0.04,
      });
    } else {
      const anim = gsap.to(lettersRef.current, {
        scrollTrigger: {
          trigger: triggerRef.current,
          // scrub: true,
          start: "top 90%",
          end: "bottom 10%",
          markers: false,
        },
        color: "#000000",
        // duration: 0,
        stagger: 0.04,
      });
      return () => {
        anim.kill();
      };
    }

    return () => {};
  }, []);

  return (
    <div className={`${styles["TitleWithArrow"]}`}>
      {withArrow && (
        <div className={`${styles["left"]}`}>
          <div className={`${styles["icon"]}`}>
            <div dangerouslySetInnerHTML={{ __html: svgImages.arrowTitle }} />
          </div>
        </div>
      )}
      <div className={`${styles["right"]}`}>
        <h2 className={`${styles["title"]}`} ref={triggerRef}>
          {text.split("").map((letter, index) => (
            <span
              className={`${styles["reveal-text"]}`}
              key={index}
              ref={setLettersRef}
            >
              {letter}
            </span>
          ))}
        </h2>
        <p className={`${styles["description"]}`}>{description}</p>
      </div>
    </div>
  );
};

export default TitleWithArrow;
